html {
  scroll-behavior: smooth;
}
body
{
  padding: 0px;
  margin: 0px;
  background: #ddd!important;
}
.offilne
{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 99;
  background:rgba(255,255,255,.6);
}
.offilneDiv
{
  position: fixed;
  background: #fff;
  z-index: 99;
  width: 300px;
  border-radius: 15px;
  bottom: 15px;
  right: 15px;
  padding: 15px;
  box-shadow: 0px 0px 10px rgb(105, 105, 105);
}
.offilne img
{
  width: 50px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
.offilne p
{
  width: calc(100% - 60px);
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0px;
}
.offilne p b
{
  display: inline-block;
  width: 100%;
  font-size: 25px;
}
div[class*="-info"] {
  background: linear-gradient(60deg, #22737d, #02b2e6) !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4) !important;
}
.btnDivs
{
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.totals p + span
{
  text-align: right;
  display: inline-block;
  position: absolute;
  width: 100%;
  right: 20px;
  bottom: 30px;
  font-size: 13px;
}
.bulk p + span
{
  text-align: right;
  display: inline-block;
  position: absolute;
  width: 100%;
  right: 20px;
  bottom: 45px;
  font-size: 13px;
}
.widgetBox.bulk p b
{
  font-size: 20px;
  padding-top: 0px;
  line-height: 15px;
  margin-bottom: 15px;
}
.bulk p + span + span
{
  text-align: right;
  display: inline-block;
  position: absolute;
  width: 100%;
  right: 20px;
  bottom: 30px;
  font-size: 13px;
}
.default-btn
{
  background: #02b2e6;
    border: #02b2e6 1px solid;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #fff;
    cursor: pointer;
}
.default-btn:hover {
  background: #008db8;
}
nav[aria-label="breadcrumb"]
{
  background: #fff;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
nav[aria-label="breadcrumb"] ol
{
  margin: 0px;
  position: relative;
  padding-left: 35px;
}
.content-body nav[aria-label="breadcrumb"] ol
{
    padding-bottom: 0px;
    padding-top: 0px;
    background: #fff;
    padding-left: 15px;
}
.content-body nav[aria-label="breadcrumb"] ol::before
{
  left: 0px;
}
nav[aria-label="breadcrumb"] ol::before
{
  content: "\f58e";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 20px;
  font-size: 12px;
  color: rgb(0, 0, 0);
}
nav[aria-label="breadcrumb"] ol li
{
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  position: relative;
  padding-left: 20px;
}
nav[aria-label="breadcrumb"] ol li a
{
  color:#017497;
}
nav[aria-label="breadcrumb"] ol li a:hover
{
  color:#02b2e6;
}
nav[aria-label="breadcrumb"] ol li:first-child
{
  padding-left: 0px;
}
nav[aria-label="breadcrumb"] ol li:first-child::before
{
  display: none;
}
nav[aria-label="breadcrumb"] ol li::before
{
  content: "\f101";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  font-weight: 900;
  left: 5px;
  color: rgb(219, 219, 219);
}
.text-center
{
  text-align: center;
}
.ag-react-container img {
  width: 100%;
  object-fit:contain;
  display: inline-block;
  height: 100%;
}
.catalogs .ag-group-value[ref="eValue"]
{
  display: none;
}
.catalogs .ag-header-container .ag-header-row .ag-header-cell:first-child, .catalogs .ag-header-container .ag-header-row .ag-header-cell:nth-child(2)
{
  display: none;
}
.catalogs .ag-header-container .ag-header-row .ag-header-cell.ag-header-cell-sortable:first-child
{
  display: block !important;
}
.catalogs .ag-column-select-list .ag-column-select-column:first-child
{
  display: none;
}
.catalogs .ag-column-select-list[ref="primaryColsListPanel"] .ag-column-select-column:nth-child(2)
{
  display: none;
}
.ag-theme-alpine.ag-popup .custom-tooltip .custom-tooltip
{
  padding: 10px !important;
  background: #fff !important;
  border: none!important;
}
.ag-theme-alpine.ag-popup .custom-tooltip 
{
  border: 1px solid #ccc !important;
}
.ag-react-container a, table td a
{
  color: #0ba6d4;
  cursor: pointer;
}
.ag-react-container a:hover, table td a:hover
{
  color: #007ca1;
}
.ag-row:nth-child(odd)
{
  /* background: #f1f1f1; */
}
.content-warpper
{
  background: #ddd;
  padding: 10px;
  padding-top: 0px !important;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.content-body
{
  background: #fff;
  display: inline-block;
  width: 100%;
  padding: 10px;
}
.content-body h4
{
  position: relative;
}
.content-body h4 b
{
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  color: #fff;  
}
.content-box
{
  /* background: #f5f5f5; */
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 5px;
}
.content-box-65
{
  width: calc(65% - 15px);
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
}
.content-box-35
{
  margin-right: 0px;
  width: 35%;
  display: inline-block;
  vertical-align: top;
}
.otherInfo
{
  width: calc(50% - 15px);
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eee;
  height: auto;
}
.content-box-2
{
  /* background: #f5f5f5; */
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 15px;
  width: calc(50% - 7.5px);
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  min-height: 195px;
}
.content-box-2:nth-child(3)
{
  margin-right: 0px;
}
.content-box p
{
  display: inline-block;
  width: 100%;
  font-weight: bold;
}
.content-box p span
{
  font-weight: normal;
  display: inline-block;
  width: 100px;
  margin-right: 10px;
  vertical-align: top;
}
.content-box p b
{
  display: inline-block;
  width: calc(100% - 170px);
  vertical-align: top;
}
.content-body h5
{
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 5px;

}
.content-body h5 button, .content-body h5 button span
{ 
  padding: 0px!important;
  color: #000 !important;
  font-size: 16px!important;
  text-transform: none;
  font-weight: bold;
}
.content-body table
{
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #efebeb;
}
.content-body table thead tr, .content-body table tbody tr
{
  /* background: #22737d; */
  border-bottom: 1px solid #efebeb;
}
.content-body table thead tr th, .content-body table tbody tr td
{
  /* text-align: center; */
  
}
.content-body table thead tr th
{
  padding-top: 3px;
  padding-bottom: 3px;
  color: #000;
  text-align: left;
  white-space: nowrap;
}
.content-body tbody tr:nth-child(odd)
{
 background: #fbfbfb !important;
}
.content-body tbody tr:nth-child(even)
{
 background: #f3f3f3 !important;
}
.content-body table tbody tr td
{
  font-weight: normal;
  padding: 5px 0px;
  font-size: 11px;
  /* background: #eee; */
}
.content-body table tbody tr td:first-child, .content-body table thead tr th:first-child
{
  text-align: left;
  padding-left: 15px;
}
.content-body .shipping, .content-body .billing
{
  padding: 10px;
  background: #fff;
  /* border: 1px solid #efebeb; */
  display: inline-block;
  padding-left: 0px;
  width: 33.33%;
  vertical-align: top;
}
.content-body h4 span
{
  background: #eaeaea;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 13px;
    color: #000;
}
.content-body .shipping h4,  .content-body .billing h4
{
  margin-top: 0px;
  font-size: 15px;
  margin-left: -10px;
  width: calc(100% + 20px);
  padding-left: 10px;
  margin-top: -10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.content-body .shipping p, .content-body .billing p
{
  font-size: 13px;
  font-weight: bold;
  /* background: #eee; */
  padding-left: 0px;
  padding-right: 5px;
  margin-bottom: 5px;
}
.content-body .shipping p span, .content-body .billing p span
{
  font-weight: normal;
  display: inline-block;
  width:150px;
}
.ag-icon
{
  cursor: pointer;
}
.orders .ag-row div[col-id="order_id"]
{
  position: relative;
  left: -5px !important;
}
.orders .ag-row div[col-id="order_id"] p
{
  position: relative;
    z-index: 99;
    overflow: visible;
    top: -41px;
    padding-left: 20px;
}
.ag-react-container {
  width: 100%;
  height: 40px;
}
.ag-theme-alpine .ag-root-wrapper
{
  border: none !important;
  border-radius: 0px!important;
}
.ag-overlay-loading-center
{
  background: #fff!important;
  border: none!important;
  box-shadow: none!important;
  font-size: 40px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.ag-overlay-loading-center
{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.nav
{
  background: #eee;
  width: 100%;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: top;
  position: relative;
}
.nav::after
{
  background: #02b2e6;
  content: "";
  height: 28px;
  bottom:-28px;
  left: 0px;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.ag-theme-alpine
{
  background: #ddd;
  padding: 10px;
  padding-top: 0px !important;
}
.ag-theme-alpine.template
{
  background:#fff;
}
.heading
{
  width: 100%;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  background: #ddd;
  position: relative;
}
.heading h1
{
  font-size: 22px;
  padding-top: 15px;
  padding-bottom: 10px;
  display: inline-block;
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 0px;
  position: relative;
}
.heading h1 span
{
  font-size: 15px;
  color: #fff;
  margin-left: 10px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  background: #02b2e6;
  border-radius: 25px;
}
.heading h1::after
{
  position: absolute;
  content: "";
  right: -50px;
  background: #fff;
  width: 50px;
  height: 50px;
  bottom: 0px;
}
.heading h1::before
{
  position: absolute;
  content: "";
  right: -50px;
  background: #ddd;
  border-radius: 0px 0px 0px 20px;
  width: 50px;
  height: 50px;
  bottom: 0px;
  z-index: 9;
}
.heading .reload
{
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
  opacity: .5;
}
.heading .reload:hover
{
  opacity: 1;
}
.searchbox
{
  position: absolute;
  right: 260px;
  bottom:10px; 
  opacity: .5;
}
.searchbox:hover, .searchbox:focus
{
  opacity: 1;
}
.searchbox input
{
  border-radius: 25px;
  height: 25px;
  border: 1px solid #ccc;
  width:180px;
  padding-right: 25px;
  padding-left: 10px;
}
.brule .searchbox
{
  right: 275px;
}
.searchbox button
{
  position: absolute;
  right: 3px;
  background: transparent;
  border: none;
  cursor: pointer;
  top: 4px
}
.nav ul
{
  display: inline-block;
  margin: 0px;
  padding: 0px;
}
.nav ul li
{
  display: inline-block;
}
.brandLogo
{
  width: 185px;
  height: 38px;
  display: inline-block;
  padding-left: 5px;
  margin-right: 10px;
  position: relative;
  top: 9px;
  z-index: 9;
}
.subMenu
{
  background: #02b2e6;
  width: 100%;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}
.subMenu ul
{
  margin-left: 190px !important;
}
.subMenu ul, .subMenu ul li
{
  display: inline-block;
  padding: 0px;
  margin: 0px;
}
.subMenu ul li
{
  padding:5px;
}
.subMenu ul li a
{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}
.subMenu ul li a:hover
{
  background:#0092bd;
  border-radius: 10px;
}
.subMenu ul li a.selected
{
  background:#0092bd;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
}
.subMenu .dropdown:hover
{
  background:#0092bd;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
}
.subMenu .dropdown
{
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
}
.subMenu .dropdown .dropbtns:hover, .subMenu .dropdown .dropbtns
{
  color: #fff;
  font-size: 13px;
  font-weight: normal;
}
.subMenu .dropdown .dropdown-content
{
  top: 20px;
  min-height: auto;
  background:#0092bd;
  left: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* min-width: 150px; */
}
.subMenu .dropdown .dropdown-content > div
{
  font-size: 12px;
  font-weight: normal;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.subMenu .dropdown:hover .dropbtns
{
  color: #fff;
}
.subMenu .dropdown .dropdown-content > div div
{
  display: inline-block;
}
.subMenu .dropdown .dropdown-content > div span
{
  float: right;
  margin-left: 10px;
  width: 100%;
  font-size: 11px;
  margin-bottom: 10px;
  cursor: pointer;
}
.search
{
  float: right;
  padding-right: 0px;
  padding-top: 2px
}
.search input
{
  border: 1px solid #0091a2;
  border-radius: 15px;
  padding:5px 10px 5px 10px;
}
.search input:-moz-placeholder, .search input:placeholder
{
  color: #fff;
}
.dropDwn
{
  float: right;
  width:230px;
  padding: 5px
}
.dropDwn select
{
  padding: 4px;
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 25px;
}
.menu-right
{
  width: 250px;
  text-align: right;
  float: right;
}
.menu-right ul li
{
  position: relative;
}
.menu-right ul li a:hover, .menu-left ul li a:hover
{
  background: #fff;
}
.menu-right ul li:hover, .menu-left ul li:hover
{
  background: #fff;
}
.menu-right ul li:hover a
{
  color: #000;
}
.menu-right ul li a
{
  color: #000;
  font-size: 13px;
  font-weight: normal;
  padding-left: 10px;
  padding-right: 10px;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  margin-top: -1px;
}
.menu-right ul li a span
{
  top: 9px;
  position: relative;
  float: left;
  margin-right: 5px;
}
.menu-right ul li a b
{
  font-size: 11px;
  position: relative;
  top:2px;
}
.menu-right ul li ul
{
  display: none;
  position: absolute;
  width: 120px;
  background: #fff;
  padding: 5px;
  right: 0;
  top: 37px;
  z-index: 999;
  box-shadow: 5px 5px 5px rgba(0,0,0,.3);
  border-radius: 0px 0px 10px 10px;
}
.menu-right ul li ul li a
{
  color: #999 !important;;
}
.menu-right ul li:hover ul
{
  display: block;
}
.menu-left, .menu-right, .dropDwn
{
  display: inline-block;
  vertical-align: top;
}
.menu-left
{
  width: calc(100% - 675px);
}
.menu-left ul li a
{
  font-size: 13px;
  font-weight: normal;
  color: #999;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 38px;
  line-height: 38px;
  display: inline-block;
}
.menu-left ul li a:hover, .menu-right ul li a:hover, .menu-right ul li ul li a:hover
{
  color: #000 !important;
}
.custom-tooltip {
  position: absolute;
  width: 150px;
  height: 70px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

/* Dropdown Button */
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 0px 10px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
.dropdown.auction
{
  position: relative;
  z-index: 99;
  margin-left: 10px;
  height: 36px;
  line-height: 36px;
}
.dropdown.auction button
{
  background: transparent;
  border: none;
  color: #666;
  font-size: 17px;
  cursor: pointer;
}
.dropdown.auction button:hover
{
  color: #000;
}
.dropdown.auction .dropdown-content p
{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: normal;
  color: #666;
  white-space: nowrap;
}
.dropdown.auction .dropdown-content p:hover
{
  background: #fff;
}
.dropdown.auction:hover .dropdown-content, .dropdown.auction .dropdown-content.show
{
 display: block;
 top: 35px;
 border-radius: 5px;
 overflow: hidden;
}
.sweet-alert h2
{
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 21px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.errorH6
{
  color: rgb(255, 45, 45);
  text-transform: none;
  background: #ffd5d5;
  border-radius: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 20px;
  font-size: 12px;
  display: none;
}
.h6ClassToShow
{
  display: block;
}
.sweet-alert 
{
  max-width: 450px;
}
.sweet-alert table tr td:first-child
{
  text-align: right!important;
  font-size: 13px;
  font-weight: normal;
  padding-right: 5px!important;
  padding-left: 0px!important;
  white-space: nowrap;
  vertical-align: top;
}
.sweet-alert table.forPublishTable tr td:first-child
{
  text-align: center!important;
}
.forPublishTable td svg
{
  width: 20px;
  height: 20px;
}
.popScroll
{
  max-height: 250px;
  overflow-y: auto;
}
.sweet-alert table 
{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-collapse: collapse;
}
.sweet-alert table tr td
{
  text-align: left;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.sweet-alert table tr td
{
  /* width: 5%; */
}
.sweet-alert .row
{
  margin-right: -15px;
  margin-left: -15px;
}
.sweet-alert button
{
  margin-right: 0px!important;
}
.sweet-alert table tr td input[type="number"], .sweet-alert table tr td select, .sweet-alert table tr td input[type="text"]
{
  border: 1px solid #ccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  overflow: hidden;
  width: 95px!important;
}
.sweet-alert table tr td span.required
{
  color: red;
  font-weight: bold;
}
.sweet-alert table tr td select
{
  /* margin-left: 10px; */
  overflow: auto!important;
  min-height: 23px;
  height: auto!important;
}
.sweet-alert table tr td select option
{
  cursor: pointer;
}
.sweet-alert table tr:nth-child(odd)
{
  background: #f1f1f1;
}
.sweet-alert table tr:nth-child(even)
{
  background: #f9f9f9;
}
.sweet-alert .btn-primary
{
  background: #02b2e6;
  border: #02b2e6 1px solid;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  color: #fff;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size:13px;

}
.sweet-alert .btn-primary:hover
{
  background: #008db8;

}
.sweet-alert .btn-link
{
  background: #ffffff;
  border: #cccccc 1px solid;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  color: rgb(39, 39, 39);
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  font-size:13px;

}
.sweet-alert .btn-link:hover
{
  background: #f7f7f7;

}
.sweet-alert svg
{
  width: 60px;
  height: 60px;
}
.modal-footer
{
  max-width:100%!important;
}
.noheading h2
{
  opacity: 0;
}
.content-box ul.timeline
{
  padding-left: 0px;
  display: inline-block;
  min-width: 300px;
  min-height: 342px;
}
.content-box ul.timeline li
{
  display: block;
  width: 100%;
}
.content-box ul.timeline li p
{
  margin-bottom: 0px;
}
.content-box ul.timeline li p span
{
  width: auto;
}
.content-box ul.timeline li div:first-child
{
  float: left;
  width: auto;
  min-width: 150px;
  font-size: 11px;
  font-weight: normal;
  position: relative;
  min-height: 10px;
  margin-right: 10px;
  text-align: right;
  padding-right: 25px;
  line-height: 15px;
}
.content-box ul.timeline li div:first-child::before
{
  position: absolute;
  top: 1px;
  width: 22px;
  height: 22px;
  border: 2px solid #ddd;
  content: "";
  right: -9px;
  border-radius: 50%;
  background: #fff;
  z-index: 9;
}
.content-box ul.timeline li.added div:first-child::before
{
  border:2px solid #02b2e6;
}
.content-box ul.timeline li div:last-child
{
  float: left;
  width: 180px;
  font-size: 12px;
  font-weight: normal;
  padding-bottom: 20px;
  position: relative;
  padding-left: 10px;
}
.content-box ul.timeline li div:last-child p
{
  white-space: nowrap;
}
.content-box ul.timeline li div:last-child::after
{
  position: absolute;
  border-left:5px solid #ddd;
  content: "";
  left: -14px;
  height: 100%;
  top: 0px;
}
.content-box ul.timeline li.added div:last-child::before
{
  display: block;
}
.content-box ul.timeline li.added div:last-child::after
{
  border-left:5px solid #02b2e6;
}
.content-box ul.timeline li div:last-child::before
{
  position: absolute;
  top: 4px;
  width: 16px;
  height: 16px;
  content: "";
  left: -20px;
  border-radius: 50%;
  background: #02b2e6;
  z-index: 9;
  display: none;
}
.content-box ul.timeline li div:last-child p:first-child
{
  font-size: 15px;
  font-weight: bold;
  margin-top: 2px;
}
.content-box ul.timeline li:last-child div:last-child::after
{
  display:none;
}
.OrderItems tr th:first-child, .OrderItems tr td:first-child
{
  width: 80px;
}
.OrderItems tr th
{
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.orderImage
{
  width: 50px;
  height: 35px;
  display: inline-block;
  background: #fff;
  line-height: 35px;
  text-align: center;
  color: #ccc;
}
.orderImage img + i
{
  display: none;
}
.orderImage img
{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sku
{
  position: relative;
  margin-bottom: 10px;
}
.sku::after
{
  position: absolute;
  content: "";
  bottom: 0px;
  left: 0px;
  width: 40%;
  border-bottom: 1px solid #eee;
}
.content-box .sku:last-child::after
{
  display: none;
}
.catlog-detail, .recetOrders
{
}
.catlog-basicinfo
{
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 15px;
}
.recetOrders, .catlog-basicinfo
{
  display: inline-block;
  width:50%;
  vertical-align: top;
}
.catlog-detail
{
  min-height: 300px;
  width:50%;
  display: inline-block;
  position:relative;
}
.clipboard
{
  position:relative;
  top:0px;
  right:0px;
  background:#fff;
  border-radius:15px;
  padding:0px 10px;
  text-transform:normal;
  font-style:normal;
  cursor:pointer;
}
.clipboard span
{
  width:auto!important;
   padding:0px 10px;
   background:#f1f1f1;
   border-radius:15px;
   margin-right:0px!important
}
.clipboard span::after
{
  display:none;
}
.clipboard svg
{
  width:20px;
  margin-left: -6px;
}
.recetOrders
{
  margin-left: 0px;
  width: calc(50% - 0px);
  position: relative;
  min-height: 300px;
  overflow: hidden;
}
.recetOrdersDetails
{
  width: 100%;
  position: relative;
  min-height: 300px;
  overflow: hidden;
  margin-top: 10px;
}
.recetOrdersDetails table
{
  border: none;
  margin-top: 10px;
}
.catlog-image
{
  display: inline-block;
  width: 250px;
  height: auto;
  vertical-align: top;
  margin-right: 25px;
  background: #fff;
  /* margin-bottom: 40px; */
}
.recetOrdersDetails .voJson
{
  border-radius: 0px 0px 10px 10px;
}
.catlog-image .image.no-image
{
  background: #f2f2f2;
}
.catlog-image .image
{
  display: inline-block;
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  background: #fff;
}
.catlog-image .react-multi-carousel-list  
{
  border-top: 1px solid #eee;
  padding-top: 10px!important;
}
.catlog-image .react-multi-carousel-list ul
{
  margin-left: auto;
  margin-right: auto;
}
.catlog-image ul li
{
  height: 45px;
  cursor: pointer;
}
.catlog-image ul li img
{
  width: 65px;
  cursor: pointer;
  object-fit: contain;
  object-position: bottom center;
}
.catlog-image .react-multiple-carousel__arrow
{
  min-width: 22px;
  min-height: 22px;
}
.catlog-image .react-multiple-carousel__arrow::before
{
  font-size: 12px;
  line-height: 20px;
}
.catlog-image .react-multiple-carousel__arrow--left
{
  left: 0px!important;
  top: 30px;
}
.catlog-image .react-multiple-carousel__arrow--right
{
  right: 0px!important;
  top: 30px;
}
.catlog-image img
{
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: top center;
}
.cat-details
{
  width: calc(100% - 285px);
  display: inline-block;
  vertical-align: top;
}
.cat-details .editPrice
{
  width: calc(100% - 110px);
}
.cat-details p
{
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
}
.cat-details p span
{
  font-weight: normal;
  display: inline-block;
  width: 110px;
  position: relative;
  margin-right: 5px;
}
.cat-details p b
{
  display: inline-block;
  width: auto;
  vertical-align: top;
  padding-left: 4px;
  white-space: normal;
  max-width: 220px;
}
.cat-details p span::after
{
  position: absolute;
  content:":";
  right: 0px;
}
.catlog-basicinfo p, .otherInfo p
{
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}
.catlog-basicinfo p,.otherInfo p
{
  display: inline-block;
  width: 220px;
}
.catlog-basicinfo p b
{
  display: inline-block;
  width: calc(100% - 215px);
  vertical-align: top;
  padding-left: 0px;
  white-space: normal;
}
.catlog-basicinfo h5:first-child
{
  margin-top: 0px;
}
.catlog-basicinfo h5, .otherInfo h5
{
  margin-top: 50px;
}
.otherInfo h5:first-child
{
  margin-top: 0px;
}
.catlog-basicinfo p span, .otherInfo p span
{
  font-weight: normal;
  display: inline-block;
  width: 200px;
  position: relative;
  margin-right: 5px;
  text-align: right;
  padding-right: 10px;
}
.editPrice
{
  width:calc(100% - 220px);
  display: inline-block;
  position: relative;
  min-width: 160px;
}
.editPrice i.fas
{
  background: transparent;
  cursor:text;
  opacity: 1;
  margin-left: 5px;
}
.editPrice i
{
  width: 20px;
  height: 20px;
  background: #02b2e6;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  opacity: .5;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
}
.editPrice i svg
{
  width: 14px;
  height: 14px;
  margin-top: 3px;

}
.editPrice i svg path:first-child
{
  fill:#fff;
}
.editPrice input
{
  border: 1px solid #eee;
  border-radius: 15px;
  padding-left: 10px;
  width: auto;
  background: #fff;
  box-shadow: inset 0px 0px 5px #00000023;
  /* min-width: 220px; */
}
.editPrice input + svg, .editPrice input + p + svg
{
  position: relative;
  width: 20px;
  height: 20px;
  top: 5px;
  left: 5px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}
.editPrice input + p
{
  width: auto;
  margin-left: 5px;
}
.editPrice button
{
  background: #02b2e6;
  border: #02b2e6 1px solid;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
  color: #fff;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 12px;
}
.editPrice button:hover
{
  background: #008db8;

}
.editPrice i:hover
{
  opacity: 1;
}
.otherInfo p b
{
  width: calc(100% - 210px);
  display: inline-block;
  vertical-align: top;
}
.catlog-basicinfo p span::after, .otherInfo p span::after
{
  position: absolute;
  content:":";
  right: 0px;
}
.catlog-basicinfo tbody tr td{
  background: transparent!important;
}
.catlog-basicinfo tbody tr:nth-child(odd)
{
  background: #fbfbfb !important;
}
.catlog-basicinfo tbody tr:nth-child(even)
{
  background: #f3f3f3 !important
}
.table-body thead
{
  opacity: 0;
  /* position: absolute; */
}
.table-header tbody
{
  opacity: 0;
}
.table-body tbody tr
{
  border-bottom: none!important;
  border-top: 1px solid #efebeb;
}
.table-header 
{
  position: relative;
  width: calc(100% - 15px);
  overflow: hidden;
  height: 40px;
  z-index: 99;
}
.table-header tr
{
  border: none!important;
}
.header-static table
{
  border: none !important;
}
.header-static table th
{
  font-size: 11px;
  padding-top: 10px!important;
  padding-bottom: 8px!important;
}
.header-static table .text-center
{
  text-align: center!important;
}
.header-static
{
  border: 1px solid #eee;
  display: inline-block;
  width: 100%;
  height: 260px;
  position: relative;
  overflow: hidden;
}
.voJson
{
  border: 1px solid #eee;
  display: inline-block;
  width: 100%;
  height: 260px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.voJsons
{
  border: 1px solid #eee;
  display: inline-block;
  width: 100%;
  height: 230px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top:10px;
}
.voJson .material-icons.rotate
{
  font-size: 16px;
  color: #666;
  margin-left: 18px;
}
p.loader
{
  width: 100%;
  display: inline-block;
  text-align: center;

}
p.loader span
{
  position: relative;
  margin-top:15%;
}
.rotate
{
  position: relative!important;
  width:auto!important;
  right:0px!important;
  -webkit-animation:1.5s spin infinite linear;
  animation: 1.5s spin infinite linear;
}
@keyframes spin {
        from { 
            transform: rotate(0deg); 
        } to { 
            transform: rotate(-360deg); 
        }
}
.recetOrders button[role="tab"], .recetOrdersDetails button[role="tab"]
{
  padding-left: 15px !important;
  margin: 0px;
  padding-right: 15px !important;
  padding-bottom: 5px!important;
  padding-top: 5px!important;
  border-radius: 10px 10px 0px 0px;
  font-size: 12px;
  border: 1px solid #eee !important;
}
.recetOrders button[role="tab"] span, .recetOrdersDetails button[role="tab"] span
{
  color: #999 !important;
}
.recetOrders h5 button span, .recetOrdersDetails h5 button span
{
  font-size: 12px!important;
}
.recetOrders button[aria-selected="true"], .recetOrdersDetails button[aria-selected="true"]
{
  background: #eee;
}
.recetOrders button[aria-selected="true"] span, .recetOrdersDetails button[aria-selected="true"] span
{
  color: #000 !important;
}
.recetOrders h5, .recetOrdersDetails h5
{
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 12px;
}
.table-header thead
{
  background: #fff;
}
.tableScroll
{
  position: absolute;
  left: 0px;
  top:1px;
  width:100%;
  max-height: 260px;
  overflow-y: auto;
  z-index: 9;
}
.noData
{
  text-align: center;
  line-height: 200px;
  display: inline-block;
  width: 100%;
  opacity: .5;
  border: 1px solid #eee;
}
.noData span
{
  line-height: 18px;
  display: inline-block;
  margin-top: 10%;
  width: 100%!important;
}
.noData i
{
  width: 100%;
  font-size: 40px;
  margin-bottom: 20px;
}
@-webkit-keyframes bounceInScale {
  0% {
    -webkit-transform: scaleX(0) scaleY(0);
    opacity: 1; }
  5% {
    -webkit-transform: scaleX(0.1) scaleY(0.1);
    opacity: 0.95; }
  10% {
    -webkit-transform: scaleX(0.25) scaleY(0.25);
    opacity: 0.85; }
  15% {
    -webkit-transform: scaleX(0.4) scaleY(0.4);
    opacity: .75; }
  20% {
    -webkit-transform: scaleX(0.6) scaleY(0.6); opacity: .65; }
  25% {
    -webkit-transform: scaleX(0.75) scaleY(0.75);  opacity: .55; }
  30% {
    -webkit-transform: scaleX(.85) scaleY(0.85);  opacity: .45; }
  35% {
    -webkit-transform: scaleX(0.9) scaleY(0.9);  opacity: .35; }
  40% {
    -webkit-transform: scaleX(0.95) scaleY(0.95);  opacity: .25; }
  45% {
    -webkit-transform: scaleX(0.98) scaleY(0.98);  opacity: .15; }
  50% {
    -webkit-transform: scaleX(1) scaleY(1); }
  55% {
    -webkit-transform: scaleX(.98) scaleY(0.98); opacity: .15; }
  60% {
    -webkit-transform: scaleX(0.95) scaleY(0.95); opacity: .25; }
  65% {
    -webkit-transform: scaleX(0.9) scaleY(0.9); opacity: .35; }
  70% {
    -webkit-transform: scaleX(0.85) scaleY(0.85); opacity: .45; }
  75% {
    -webkit-transform: scaleX(0.80) scaleY(0.80);  opacity: .55; }
  80% {
    -webkit-transform: scaleX(0.70) scaleY(0.70); opacity: .65; }
  85% {
    -webkit-transform: scaleX(0.65) scaleY(0.65); opacity: .75; }
  90% {
    -webkit-transform: scaleX(0.40) scaleY(0.40); opacity: .9; }
  100% {
    -webkit-transform: scaleX(0) scaleY(0);
    opacity: 1.000; } }
    .content-box ul.timeline li.last:last-child div::after
{
  -webkit-animation: bounceInScale 1.5s infinite 0s ;
  -webkit-transform-origin: 50% 50%;
  animation: bounceInScale 1.5s infinite ;
  transform-origin: 50% 50%; 
  position: absolute;
  width: 39px;
  height: 39px;
  background: #039fce71;
  /* border: 1px solid #2980B9; */
  content: "";
  border-radius: 50%;
  top: -7px;
  left: 129px;
}
.OrderItems td{
  padding-left: 5px!important;
  padding-right: 5px!important;
}
.OrderItems  tr
{
  border-bottom: none!important;
}
.OrderItems .grandTotal td
{
  background: #fff!important;
  text-align: right;
  padding-right: 20px;
  font-weight: bold;
  padding-top: 3px!important;
  padding-bottom: 3px!important;
}
.OrderItems .grandTotal td:last-child
{
  text-align: left!important;
  padding-right: 0px!important;
}
.dashboard-content
{
  padding: 0px 10px;;
  display: inline-block;
  width: 100%;
  background: #ddd;
}
.myDash
{
  background: #fff;
  padding: 40px;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 15px;
}
.ordersDash, .productsDash
{
  padding: 40px;
  border: 1px solid #eee;
  border-radius: 30px;
  position: relative;
  margin-bottom: 40px;
}
.ordersDash
{
  margin-bottom: 0px;
}
.ordersDash span.headePannel, .productsDash span.headePannel
{
  position: absolute;
  top: -15px;
  background: #02b2e6;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  left: 40px;
  min-width: 110px;
}
.widgetBox
{
  width: calc(25% - 15px);
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}
.widgetBox:nth-child(5)
{
  margin-right: 0px;
}
.widgetIcon
{
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  width: 50px;
  display: inline-block;
  vertical-align: top;
  line-height: 20px;
  text-align: center;
}
.widgetIcon i
{
  font-size: 24px;
}
.widgetIcon.blue
{
  background: #02b2e6;
}
.widgetIcon.yellow
{
  background: #fec400;
}
.widgetIcon.red
{
  background: #fd5e00;
}
.widgetIcon.green, h4 .green
{
  background: #2bd600;
}
.widgetIcon.grey, h4 .grey
{
  background: #b4b4b4;
}
.widgetIcon.darkBlue
{
  background:#6235c3;
}
.widgetIcon.violet
{
  background:#6e0f8c;
}
.widgetBox p
{
  width: calc(100% - 50px);
  display: inline-block;
  text-align: right;
  color: #999;
  position: relative;
}
.widgetBox p span.material-icons
{
  position: absolute!important;
  right: 53px !important;
  width: 25px!important;
  height: 25px!important;
  margin-top: -3px;
}
.widgetBox.bulk + .widgetBox.bulk.inventry p span.material-icons
{
  right: 86px !important;
}
.widgetBox.totals + .widgetBox p span.material-icons
{
  right: 86px !important;
}
.widgetBox.bulk.inventry + .widgetBox.bulk.inventry p span.material-icons
{
  right: 120px !important;
}
.widgetBox.bulk p span.material-icons
{
  right: 86px !important;
}
.widgetBox.newpdts p span.material-icons
{
  right: 90px !important;
}
.widgetBox p.times
{
  width: 100%;
  font-size: 11px;
  position: absolute;
  right: 20px;
  bottom: -1px;
  margin-bottom: 0px;
  line-height: 25px;
}
.widgetBox p.times span
{
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}
.widgetBox p b
{
  width: 100%;
  display: inline-block;
  font-size: 30px;
  padding-top: 5px;
  color: #000;
}
.widgetFooter
{
  width: calc(100% + 40px);
  display: inline-block;
  margin-top: 10px;
  background: #f5f5f5;
  height: 30px;
  margin-left: -20px;
  vertical-align: bottom;
  border-radius: 0px 0px 18px 18px;
  border-top: 1px solid #ddd;
}
.profileConfig
{
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #fff;
  border:1px solid #ccc;
  border-radius: 25px;
  padding-left: 8px;
  white-space: nowrap;
  /* overflow: hidden; */
  height: 22px;
}
.profileConfig .profiles select
{
  border: none;
  background: transparent;
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  height: 22px;
  position: relative;
  padding-right: 15px;
  cursor: pointer;
  background: url("../img/arrow.png") no-repeat 98% center;
  background-size: 12px 12px;
  margin-right: 5px;
  min-width: 65px;
}
.profileConfig input
{
  border: none;
  width: 70px;
}
.profileConfig button
{
  border: none;
  background: #f1f1f1;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
}
.profileConfig button.prosave
{
  background: #02b2e6;
  color: #fff;
}
.profileConfig .profiles
{
  display: inline-block;
  position: relative;
  /* margin-right: 15px; */
  
}
.bulkDeatils table
{
  background: #fff;
}
/* .profileConfig .profiles::after
{
  position: absolute;
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  right: 10px;
  top: 1px;
  z-index: 0;
  width: 5px;
  height: 5px;
  color: #000;
} */
.profileConfig .btnDrpDwn
{
  display: inline-block;
  position: relative;
}
.profileConfig .btnDrpDwn::after
{
  position: absolute;
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  right: 10px;
  top: 1px;
  z-index: 9;
  width: 5px;
  height: 5px;
  color: rgb(255, 255, 255);
}
.profileConfig .btnDrpDwn select
{
  background: #02b2e6;
  margin-right: 0px !important;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding-left: 30px;
  appearance: none;
  height: 22px;
  padding-right: 20px;
  border: none;
  text-align: left;
}
.profileConfig button
{
  border: none;
  background: transparent;
}
.profileConfig .dropdown.auction
{
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  margin-left: 0px;
}
.profileConfig .dropdown.auction button{
  font-size: 12px;
  background: #02b2e6;
  height: 21px;
  display: inline-block;
  top: -1px;
  position: relative;
  color: #fff;
  font-weight: bold;
  border-radius: 0px 10px 10px 0px;
  padding-right: 20px;
  padding-left: 10px;
  width: 80px;
  position: relative;
}
.brule .profileConfig .dropdown.auction button
{
  background: #fff;
  color: #000;
  height: 20px;
  line-height: 21px;
  top: 0px;
}
.profileConfig .dropdown.auction button::after
{
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #057596;
  content: "";
  top: 9px;
  right: 5px;
}
.profileConfig .dropdown.auction i
{
  font-size: 10px;
  margin-right: 5px;
}
.profileConfig .dropdown-content
{
  right: 0px;
  min-width: 80px;
  /* max-width: 80px; */
  top: 20px !important;
  box-shadow: none;
  z-index: -1;
  border-radius: 0px 0px 10px 10px !important;
  padding-top: 0px !important;
  border: 1px solid #ccc;
}
.profileConfig .dropdown-content p
{
  font-size: 12px !important;
}
.ag-header-cell.ag-focus-managed
{
  border: none!important;
}
.ag-header-row.ag-header-row-column
{
  border-bottom: 1px solid #ccc;
}
.ag-header-cell.ag-focus-managed.ag-header-cell-sortable.ag-column-menu-visible.ag-header-cell-filtered
{
  /* background: #ffffd3; */
}
.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child.ag-keyboard-focus input
{
  /* background: #ffffd3; */
}
.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child.ag-keyboard-focus .ag-wrapper.ag-picker-field-wrapper
{
  /* background: #ffffd3; */
}
input[value]
{
  /* background: #FFF!important; */
}
/* input:not(:placeholder-shown) {
  background: #ffffd3 !important;
} */
input:focus {
  /* background: #ffffd3 !important; */
}
.yellowFilterInput{
  background: #ffffd3 !important;
}
.ag-row.ag-row-loading
{
  /* position: absolute;
  top: -100px;
  left: 0px;
  text-align: center;
  z-index: 999; */
}
.dashFilter
{
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: .5;
}
.dashFilter input {
  border-radius: 25px;
  height: 25px;
  border: 1px solid #ccc;
  width: 150px;
  padding-right: 25px;
  padding-left: 10px;
}
.dashFilter button {
  position: absolute;
  right: 3px;
  background: transparent;
  border: none;
  cursor: pointer;
  top: 4px;
  opacity: .6;
}
.dashFilter button:hover
{
  opacity: 1;
}
.toggleBtn
{
  position: absolute;
  right: -10px;
  top: -5px;
}
.toggleBtn svg
{
  width:35px;
  height: 35px;
  cursor: pointer;
}
.toggleBtn svg.on path:first-child
{
  fill: rgb(0, 185, 0);
}
.toggleBtn svg.off path:first-child
{
  fill: rgb(155, 155, 155);
}
.sweet-alert p i
{
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  background: #e9f5ff;
  display: inline-block;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

.advFilter {
  display: inline-block;
  position: relative;
    z-index: 9;
    margin-left: 15px;
}
.advFilterSelect {
  /* width: 15%; */
  display: inline-block;
  margin-right: 10px;
  background: #fff;
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 0px;
}
.advFilterSelect select
{
  background: transparent;
  border: none;
  padding-right: 10px;
  margin-right: 5px;
}
.advFilterSelect button
{
  border: none;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  padding-right: 10px;
  padding-left: 10px;
  height: 21px;
  cursor: pointer;
  opacity: .3;
}
.advFilterSelect button:hover
{
  opacity: 1;
}
.advFilterEdit
{
  background: #02b2e6;
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;
  border: none;
  border-radius: 15px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}
.advFilterEdit:hover
{
  background: #0092bd;
}
.advFilterQuery
{
  padding-right: 10px;
  padding-left: 10px;
  display: inline-block;
  background: #ddd;
  width: 100%;
}
.advFilterQuery .query-builder-container
{
  background: #fff;
}
.group
{
  background: #f2f2f2;
  border: 1px solid rgb(219, 219, 219);
  padding-right: 10px;
}
.group-or-rule-container
{
  padding-right: 0px;
}
.advFilterQueryBtn
{
  background: #fff;
  padding-left: 26px;
  position: relative;
  top: -10px;
  padding-bottom: 20px;
}
.advFilterQueryBtn button:first-child, .group--actions button
{
  background: #02b2e6;
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;
  border: none;
  border-radius: 15px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}
.group--actions button:last-child
{
  margin-right: 0px;
}
.advFilterQueryBtn button:first-child:hover, .group--actions button:hover
{
  background: #0092bd;
}
.advFilterQueryBtn button:last-child
{
  background: #f3f3f3;
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;
  border: none;
  border-radius: 15px;
  font-size: 12px;
  color: rgb(56, 56, 56);
  cursor: pointer;
}
.advFilterQueryBtn button:last-child:hover
{
  background: #e7e7e7;
}

.bulkDeatils table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.bulkDeatils th
{
  font-size: 13px;
}
.bulkDeatils td, .bulkDeatils th {
  border-bottom: 1px solid #dde2eb;
  text-align: left;
  padding: 10px;
}

.bulkDeatils tr:nth-child(odd) {
  background-color: #fcfcfc;
}
.bulkDeatils td:nth-child(3), .bulkDeatils th:nth-child(3)
{
  text-align: center;
}
.dashDropdwn
{
  position: absolute;
  right: 170px;
  bottom: 10px;
  opacity: 1;
  padding-left: 15px;
  padding-right: 5px;
  height: 23px;
}
.dashDropdwn label
{
  font-size: 11px;
  margin-right: 10px;
  font-weight: bold;
}
.dashDropdwn::after
{
  position: absolute;
  height: 23px;
  width: 23px;
  background: #fff;
  border-radius: 15px;
  content: "";
  right: 0px;
  z-index: 1;

}
.dashDropdwn select
{
  border: none;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  background: #fff;
  border-radius: 15px;
  height: 23px;
  position: relative;
  z-index: 9;

}
.platformSelect
{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
}
.platformSelect select
{
  padding: 3px 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin-right: 10px;
}
.platformSelect button
{
  background: #0092bd;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;

}
.content-body h4 b div
{
  position: absolute;
  display: none;
  right: 0px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  z-index: 99;
}
.content-body h4 b
{
  cursor: pointer;
}
.content-body h4 b div span
{
  display: inline-block;
  background: #fff;
  padding: 0px;
}
.content-body h4 b:hover div
{
  display: block;
}

.dropbtns {
  /* background-color: #4CAF50; */
  color: white;
  /* padding: 10px; */
  font-size: 12px;
  /* border: none; */
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: white; */
  min-width: 150px;
  /* min-height: 150px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 0px 10px;
  text-decoration: none;
  display: block;
  font-size: 13px;
  font-weight: normal;
}
.configDiv
{
  /* min-height: 300px; */
  /* height: 100%; */
}
.configTable
{
  width: auto !important;
  min-width: 500px;
  margin-top: 25px;
}
.configTable tr:first-child
{
  border-bottom:1px solid #eee;
 }
.configTable td
{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.configTable td:first-child
{
  width: 150px;
}
.configTable td:last-child
{
  text-align: left;
}
.configTable td span i
{
  background: #02b2e6;
  height: 20px;
  width: 20px;
  border: none;
  border-radius: 15px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 10px;
  opacity: .8;
}
.configTable td span i:hover
{
  opacity: 1;
}
.configTable td span i svg
{
  width: 12px;
  height: 12px;
  margin-top: 4px;
}
.dropdown-content a:hover {background-color: #fff;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtns {color: #e21313;}
.css-yk16xz-control
{
  min-height:auto!important;
}
.css-yk16xz-control svg, .css-1pahdxg-control svg
{
  width:20px;
  height:20px;
  cursor:pointer;
}
.css-tlfecz-indicatorContainer
{
  padding-top:0px !important;
  padding-bottom:0px!important;
}
#gridSweetId
{
  min-height:150px;
}
.bulkDeatils th {
  border-bottom: 1px solid #dde2eb;
  text-align: left;
  padding: 10px;
}

.bulkDeatils tr:nth-child(odd) {
  background-color: #fcfcfc;
}
.bulkDeatils td:nth-child(3), .bulkDeatils th:nth-child(3)
{
  text-align: center;
}
.dashDropdwn
{
  position: absolute;
  right: 170px;
  bottom: 10px;
  opacity: 1;
  padding-left: 15px;
  padding-right: 5px;
  height: 23px;
}
.dashDropdwn label
{
  font-size: 11px;
  margin-right: 10px;
  font-weight: bold;
}
.dashDropdwn::after
{
  position: absolute;
  height: 23px;
  width: 23px;
  background: #fff;
  border-radius: 15px;
  content: "";
  right: 0px;
  z-index: 1;

}
.dashDropdwn select
{
  border: none;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  background: #fff;
  border-radius: 15px;
  height: 23px;
  position: relative;
  z-index: 9;

}
.platformSelect
{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
}
.platformSelect select
{
  padding: 3px 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin-right: 10px;
}
.platformSelect button
{
  background: #0092bd;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;

}
.content-body h4 b div
{
  position: absolute;
  display: none;
  right: 0px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  z-index: 99;
}
.content-body h4 b
{
  cursor: pointer;
}
.content-body h4 b div span
{
  display: inline-block;
  background: #fff;
  padding: 0px;
}
.content-body h4 b:hover div
{
  display: block;
}

.dropbtns {
  /* background-color: #4CAF50; */
  color: white;
  /* padding: 10px; */
  font-size: 12px;
  /* border: none; */
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: white; */
  /* min-height: 150px; */
  /* min-height: 150px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 0px 10px;
  text-decoration: none;
  display: block;
}
.configDiv
{
  /* min-height: 300px; */
  /* height: 100%; */
}
.configTable
{
  width: auto !important;
  min-width: 500px;
  margin-top: 25px;
}
.configTable tr:first-child
{
  border-bottom:1px solid #eee;
 }
.configTable td
{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.configTable td:first-child
{
  width: 150px;
}
.configTable td:last-child
{
  text-align: left;
}
.configTable td span i
{
  background: #02b2e6;
  height: 20px;
  width: 20px;
  border: none;
  border-radius: 15px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 10px;
  opacity: .8;
}
.configTable td span i:hover
{
  opacity: 1;
}
.configTable td span i svg
{
  width: 12px;
  height: 12px;
  margin-top: 4px;
}

.dropdown-content a:hover {background-color: #fff;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtns {color: #e21313;}
.css-yk16xz-control
{
  min-height:auto!important;
}
.css-yk16xz-control svg, .css-1pahdxg-control svg
{
  width:20px;
  height:20px;
  cursor:pointer;
}
.css-tlfecz-indicatorContainer
{
  padding-top:0px !important;
  padding-bottom:0px!important;
}
#gridSweetId
{
  min-height:150px;
}

.create_business_rule
{
  position: absolute;
  right: 110px;
  bottom:10px; 
}
.create_business_rule button
{
  border: 1px solid #ccc;
  background: #fff;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
  height: 23px;
  line-height: 21px;
  cursor: pointer;
}
.create_business_rule button:focus
{
  outline: none;
  box-shadow: none;
}
.modal-dialog {
  max-width: 80% !important;
  /* max-height: 80% !important; */
  height: 900px !important;
}

.create_business_rule_tab
{
  margin-left: 25px;
  width: calc(80% - 25px);
  position: relative;
  min-height: 300px;
  overflow: hidden;
}

.create_business_rule_tab
{
  display: inline-block;
  width:50%;
  vertical-align: top;
}
.CreateBR .modal-title
{
  margin-top: 0px;
  font-size: 20px;
}
.CreateBR .modal-header
{
  padding-top: 5px;
  padding-bottom: 5px;
  
}
.CreateBR .modal-body
{
  padding-left:15px;
  padding-right:15px;
}
.CreateBR .close
{
  outline:none;
  box-shadow:none;
}
.CbrL label
{
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #000;
}
.CbrL input
{
  display: inline-block;
  width: 100%;
  border:1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.CbrL textarea
{
  display: inline-block;
  width: 100%;
  border:1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 5px;
  min-height: 50px;
  padding: 5px;
}
.CbrTabs button
{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  text-transform: none;
}
.CbrTabs button span{
  color: #000;
}
.CbrTabs button[aria-selected="true"]
{
  border-bottom: 2px solid #0091a2 !important;
  border-radius: 0px;
  outline: none;
  color: #0091a2 !important;

}
.CbrTabs button[aria-selected="true"] span
{
  color: #007bff !important;
}
.CbrTabs [role="region"] p
{
  padding-left:0px;
  border-left: 10px solid #eee;
}
.CbrTabs [role="region"] > div
{
  padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
}
.CbrTabs [role="region"] table
{
  width:100%;
  display: inline-table;
}
.CbrTabs [role="region"] table tr th
{
  padding-left:20px;
  border-bottom:1px solid #eee;
}
.CbrTabs [role="region"] table tr td
{
  padding-left:20px;
  border-bottom:1px solid #eee;
}
.CbrRow
{
  background: #eee;
  padding-right: 10px;
  padding-left: 0px;
  padding-top:10px;
  padding-bottom:10px;
    border:1px solid #ccc;
    border-bottom:0px;
}
.CbrRow + div
{
  background:#f9f9f9;
  padding-left:10px;
    border:1px solid #ccc;
    border-top:0px;
}
.CbrRow label
{
  font-size: 12px;
  color: #000;
  display: inline-block;
  margin-right:10px;
  margin-left:10px;
}
.CbrRow label + div
{
  width: calc(100% - 155px);
  display: inline-block;
}
.CbrRow label + div > div.cus_select
{
  width: calc(50% - 20px);
  display: inline-block;
}
.CBREditor
{
  width:100%;
  display:inline-block;
}
.CBREditor > label
{
  font-size: 12px;
  color: #000;
  display: inline-block;
  margin-right:10px;
  margin-left:0px;
  margin-bottom:0px;
  margin-top:10px;
  background: #eee;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight:bold;
  border:1px solid #ccc;
  border-bottom:0px;
  width:100%;
  line-height:22px;
}
.CBREditor textarea
{
  display: inline-block;
  width: 100%;
  border-top:0px;
  border:1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  min-height:310px;
  border-radius:0px;
  
}
.CbrR
{
  max-height:720px;
  overflow-y:auto;
}
.CbrRow [role="combobox"]
{
  background: #fff;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
.CbrRow [role="combobox"] input
{
  padding: 0px !important;
  margin-top: 0px;
    font-size: 13px;
}
.CbrRow [role="combobox"] > div > div
{
  padding-top: 0px;
  padding-bottom: 0px;
  height: 30px;
}
.CbrTabs div > p
{
  display: inline-block;
  width: 100%;
  text-align: left;
  line-height: 28px;
  background: #f8f8f8;
  border: 1px solid #eee;
  position: relative;
  padding-left: 25px;
  height: 30px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
.CbrTabs div > p > div
{
  display: inline-block!important;
}
.CbrTabs div > p  div[aria-hidden="true"]
{
  padding: 0px;
  position: absolute;
  top: 6px;
  left: 4px;

}
.CbrTabs div > p  div[aria-hidden="true"] svg
{
  width: 16px;
  height: 16px;
}
.CbrTabs div > p  div[aria-controls="panel1a-content"]
{
  position: absolute;
  left: 0px;
  height: 30px;
  min-height: auto;
  width: 100%;
}
.CbrTabs div[class*="rounded"]
{
  border:none;
  border-radius: 0px;
  width: calc(100% - 4px);
  box-shadow: none;
}
div[x-placement="bottom"] li
{
  font-size: 13px;
}
.updateMsg
{
  font-size:13px;
  padding-top: 15px;
    padding-bottom: 15px;
}
.updateMsg span
{
  font-style:italic;
  font-weight:bold;
  text-decoration: underline;
}
.sweet-alert svg.Updated {
    width: 50px;
    height: 50px;
    fill: mediumseagreen;
}
i.fa-trash
{
  cursor:pointer;
  opacity:.4;
}
i.fa-trash:hover{
  opacity:1;
}
div[data-target="#exampleModalCenter"]
{
  cursor:pointer;
}
.udoBtn
{
  height:20px;
}
.udoBtn svg
{
  width:20px;
  position:relative;
  height:20px;
  padding: 3px;
    border:1px solid #dbdbdb;
  border-radius:3px;
  margin-left:5px;
}
.udoBtn svg:hover
{
  background:#dbdbdb;
}
.css-1hwfws3
{
  height:25px;
}
.css-1wa3eu0-placeholder
{
  font-size:13px;
}
.css-1pahdxg-control{
  min-height:25px !important;
}
.css-26l3qy-menu div
{
  font-size:13px;
}
.css-1rhbuit-multiValue div
{
  font-size:12px;
}
#free-solo-2-demo
{
  font-size:12px;
  padding:0px;
}
#free-solo-2-demo-popup
{
  font-size:12px;
}
.template .ag-react-container > div > div > div div
{
  text-align:left;
}
.template .ag-react-container > div > div > div div
{
  text-indent:18px;
}
.template .ag-react-container > div > div > div div:first-child
{
  text-indent:45px;
}
.template .ag-react-container > div > div > div div:last-child
{
  text-indent:18px;
}
.templateBox
{
  border-radius:0px;
  padding:10px;
  margin-right:10px;
  position:relative;
}
.templateBox div, .templateBox
{
  text-indent:0px !important;
  /* padding-top:0px; */
}
.templateBox div[role="tablist"]
{
  border:1px solid #eee;
  font-size:11px;
}
.templateBox div[role="tablist"] button span
{
  font-size:12px !important;
  font-weight:normal!important;
}
.templateBox div[role="tablist"] button 
{
  border-right:1px solid #eee!important;
  padding-left:15px!important;
  padding-right:15px!important;
  border-radius:0px!important;
  background:#fafafa;
  margin:0px!important;
  outline:none!important;
  box-shadow:none!important;
}
.templateBox div[role="tablist"] button:hover, .templateBox div[role="tablist"] button[aria-selected="true"]
{
  background:#02b2e6;
}
.templateBox div[role="tablist"] button:hover span, .templateBox div[role="tablist"] button[aria-selected="true"] span
{
  color:#fff!important;
}
.templateBox h5
{
  margin-top:5px!important;
  border:0px!important;
}
.templateBox textarea
{
  width:100%;
  margin-top:5px;
  border:1px solid #eee;
}
.templateBox button[type="button"]
{
  border:1px solid #cccc;
  margin-top:10px;
  border-radius:0px!important;
  margin-right:10px;
  font-size:12px !important;
  font-weight:normal!important;
  cursor:pointer;
  outline:none!important;
  box-shadow:none!important;
}
.templateBox button[type="button"]:hover
{
  background:#02b2e6;
  color:#fff!important;
}
.templateBox div[role="combobox"] button[type="button"]
{
  background:#fff;
  color:#000!important;
  margin-top:0px;
  border:0px!important;
  margin-right:0px;
}
.checkBoxDivs
{
  display:inline-block;
  width:100%;
  height: 480px;
  overflow-y: auto;
}
.checkBoxDivs div
{
  width:50%;
}
.checkBoxDivs div input
{
  margin-right:5px;
}
.checkBoxDivs div, .checkBoxDivs div p, .checkBoxDivs div input
{
  display:inline-block;
}
.configSave
{
  position: absolute;
  right: 10px;
  top: 20px;
  background: #02b2e6;
  border: #02b2e6 1px solid;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
  padding-top: 0px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 13px;
}
.configSave:hover
{
  background:#02b2e6;
}
.CERule
{
  display:inline-block;
  position:absolute;
  right:10px;
  bottom: 15px;
  color:#02b2e6;
  cursor:pointer;
}
.CERule + .CERule
{
  right:110px!important;
}
.template .ag-overlay
{
  background:rgba(255,255,255,.8);
  font-family:"Roboto";
}
.ClearSku
{
  cursor: pointer;
  display: inline-block;
  margin-left: calc(100% - 54px);
  font-size: 11px;
  text-align: right;
  color:#007bff;
  white-space:nowrap;
}
.ClearSku + a
{
  background:#007bff;
  color:#fff;
  text-align:center;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  padding-bottom: 3px;
  padding-top: 3px;
}
.CERulePopup h3
{
  margin-top:0px;
}
.CERulePopup h3 + button
{
  position: absolute;
  right: 15px;
  border: none;
  background: transparent;
  top: 15px;
  font-size: 20px;
  cursor:pointer;
}
.CERulePopup h3 + button:outline
{
  border:none;
  box-shadow:none;
}
.CERulePopup-Footer
{
  width:100%;
  display:inline-block;
  text-align: right;
}
.CERulePopup-Footer button:first-child
{
  border: 1px solid #e1e1e1;
  border-radius: .2rem;
  height: 31px;
  margin-right:15px;
  padding-left:15px;
  padding-right:15px;
}
.template .ag-custom-loading-cell
{
  padding-left:25px!important;
  line-height:40px!important;
}
.lookupModal .CbrL table
{
  width:100%;
}
.lookupModal .CbrL table th
{
  background:#f1f1f1;
  padding-left:5px;
  padding-right:5px;
  width:40%;
}
.lookupModal .CbrL table th input
{
  width:100%;
}
.lookupModal .CbrL table th:last-child
{
  width:20%;
}
.lookupModal .CbrL table th button
{
  border-radius: 5px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 18px;
  background: #007bff;
  color: #fff;
  line-height: 23px;
  height: 23px;
  cursor: pointer;
  border: none;
}
.lookupModal .CbrL table td
{
  padding-left:5px;
  padding-right:5px;
  border-bottom:1px solid #eee;
  font-size:12px;
  font-weight:normal;
  width:40%;
}
.lookupModal .CbrL table td:last-child
{
  width:20%;
}
.lookupModal .CbrL table td span
{
  padding-right:5px;
  font-size:11px;
  padding-left:5px;
  border-right:1px solid #ccc;
  color:#007bff;
  cursor:pointer;
}
.lookupModal .CbrL table td span:last-child
{
  border: none;
}
.lookupModal .addItemList
{
    background: #f1f1f1;
}
.lookupList .CERule + .CERule
{
  right:80px!important;
}
.lookupModal .CbrL.mb-3 div
{
  max-height:125px;
  overflow-y:auto;
}
.div50
{
  display:inline-block;
  width:50%;
  vertical-align:top;
}
.div50:first-child
{
  width:calc(50% - 10px);
  margin-right:10px;
}
.div50 div
{
  padding-top:0px !important;
  padding-bottom:0px!important;
  border:none;
  outline:none;
}
.div50 input[type="search"]
{
  border:none;
  margin-top:5px;
  height:auto;
}
.div50 input
{
  height:27px;
}
.div50 fieldset
{
  height:25px;
  border: 1px solid #ccc !important;
}
.rowDrop div
{
  width:20%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.BasicPdts h5
{
  border:none;
}
.BasicPdts h5 button, .BasicPdts h5 button span
{
  font-size:11px !important;
  /* font-weight:normal!important; */
  text-transform:uppercase;
  line-height:23px !important;
}
.BasicPdts button#product, .BasicPdts button#Image, #child_product button.btn.btn-primary
{
  padding-left:15px!important;
  padding-right:15px!important;
  outline:none!important;
  box-shadow:none;
  font-size:11px !important;
  border-radius:20px;
  background:#02b2e6;
  color:#fff!important;
  margin-bottom:10px;
  border:none;
  cursor:pointer;
  margin-right:15px;
  text-transform:uppercase;
}
#child_product button.btn.btn-primary
{
  margin-top:0px;
  padding-bottom: 4px;
}
#child_product table td button
{
  background:#02b2e6;
  padding-left:10px!important;
  padding-right:10px!important;
  font-size:11px !important;
  border-radius:20px;
  border:none;
  color:#fff!important;
  cursor:pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}
#child_product button:hover
{
  background:#0392bc!important;
}
.proBtns
{
  position:absolute;
  right:15px;
  top:20px;
}
.proBtns button
{
  padding-left:15px!important;
  padding-right:15px!important;
  outline:none!important;
  box-shadow:none;
  font-size:11px !important;
  border-radius:20px;
  background:#02b2e6;
  color:#fff!important;
  font-weight:bold;
  margin-bottom:10px;
  border:none;
  cursor:pointer;
  margin-right:10px;
}
.proBtns a{
  color:#000;
  font-size:11px !important;
  border:1px solid #ccc;
  padding-left:15px!important;
  padding-right:15px!important;
  line-height:23px !important;
  border-radius:20px;
  height: 23px;
  display: inline-block;
  background:#fff;
}
.BasicPdts h5 button[aria-selected="true"]
{
  
}
.BasicPdts h5 button[aria-selected="true"] span
{
  color:#fff!important;
}
.infoBox
{
  border:1px solid #eee;
  border-radius:10px;
  padding: 15px 20px;
  background:#f2f2f2;
}
.infoBox h5
{
  border-bottom: 1px solid #ccc !important;
}
.infoBox label
{
  font-size:12px;
  width:300px;
  text-align:right;
  display:inline-block;
  padding-right:10px;
  vertical-align:top;
  padding-top: 5px;
  margin-bottom: 12px;
  color:#666;
}
.infoBox .form-control, .infoBox select, .infoBox textarea
{
  display:inline-block;
  width:calc(80% - 300px);
  font-size:13px;
  padding: 3px 10px!important;
  height:auto!important;
  color:#000;
  box-shadow: 1px 1px 2px 0px #dbdbdb;
  border: 1px solid #dddddd;
}
.infoBox .form-control, .infoBox select
{
  margin-right: 20%;
}
.infoBox input.form-control
{
  height:27px!important;
}
.infoBox .form-control#storeTitleForBasic
{
  margin-right: 0px;
}
.infoBox .input-group input
{
  width: calc(80% - 700px);
  flex: none;
  border-top-right-radius: .25rem!important;
  border-bottom-right-radius: .25rem!important;
}
.infoBox .input-group select, .infoBox .input-group textarea
{
  display:inline-block;
  width:calc(80% - 300px);
  font-size:13px;
  padding: 3px 10px!important;
  flex: none;
  margin-bottom: 7px;
  border-radius: .25rem!important;
}
.infoBox .input-group .input-group-prepend
{
  display: inline-block
}
.infoBox .input-group .input-group-text
{
  font-size:13px;
  padding: 5px 10px!important;
  border-top-left-radius: .25rem!important;
  border-bottom-left-radius: .25rem!important;
  height: 27px;
  box-shadow: 1px 1px 2px 0px #dbdbdb;
}
.infoBox table
{
  margin-bottom:20px;
  box-shadow: 1px 1px 2px 0px #dbdbdb;
}
.infoBox table th, .infoBox table td 
{
  padding-left:10px;
  padding-right:10px;
  font-size:12px;
}
.infoBox table th
{
  background: #eee;
}
.infoBox table td 
{
  font-weight:normal;
  border-top: 1px solid #efebeb;
  background: #fefefe;
}
.textCopy
{
  font-size: 11px;
  font-weight: normal;
  background: #fefefe;
  border: 1px solid #ccc;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 10px;
  cursor:pointer;
  box-shadow: 1px 1px 2px 0px #dbdbdb;
}
.textCopy:hover
{
  background: #f1f1f1;
}
.RTMenu
{
  position: fixed;
  right: 35px;
  width: 205px;
  box-shadow: 1px 1px 2px 0px #dbdbdb;
  border-radius:10px;
}
.RTMenu button
{
  width:100%;
  text-align:left;
  border:1px solid #eee;
  background:#fefefe;
  padding:5px 10px;
  cursor:pointer;
  margin-top:-1px;
  outline:none!important;
  box-shadow:none;
}
.RTMenu button a
{
  color:#02b2e6;
}
.RTMenu button:hover
{
  background:#02b2e6;
  color:#fff!important;
  border:1px solid #02b2e6;
}
.RTMenu button i
{
  color:#02b2e6;
}
.RTMenu button:hover a, .RTMenu button:hover i
{
  color:#fff!important;
}
.RTMenu button:first-child
{
  border-radius:10px 10px 0px 0px;
}
.RTMenu button:last-child
{
  border-radius:0px 0px 10px 10px;
}
.imageUpload .ImagUploadBox
{
  background: #fff;
  border-radius: 5px;
  margin: 8px;
  max-width: calc(50% - 16px);
  flex-basis: calc(50% - 16px);
  padding: 10px !important;
}
.imageUpload .ImagUploadBox h6
{
  margin-top: 0px;
  text-transform: none;
  font-weight: bold;
}
.imageUpload .ImagUploadBox > div > div
{
  height: 120px;
  max-width: calc(33.333333% - 16px);
  flex-basis: calc(33.333333% - 16px);
  margin: 0px 8px 8px 8px;
  padding: 5px !important;
  border-radius: 5px;
  background: #f2f2f2;
  
}
.imageUpload .ImagUploadBox > div > div + div
{
  max-width: calc(66.666667% - 16px)!important;
  flex-basis: calc(66.666667% - 16px)!important;
  background: #fff;
  text-align: right;
}
.imageUpload .ImagUploadBox > div > div + div textarea + button, .importbtn, .imageUploadPop div[class*="paper"] button, .imageUploadPop div[data-test="sentinelStart"] + div button
{
  border-radius: 20px;
  background: #02b2e6;
  color: #fff!important;
  border: none;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  outline: none;
}
.importbtn
{
  margin-bottom: 10px;
}
.imageUpload .ImagUploadBox > div > div + div textarea + button:hover, .importbtn:hover, .imageUploadPop div[class*="paper"] button:hover, .imageUploadPop div[data-test="sentinelStart"] + div button:hover
{
  background: #029fcf;
}
.imageUpload .ImagUploadBox > div > div + div textarea + button + button, .imageUploadPop div[class*="paper"] button + button
{
  border-radius: 20px;
  background: #e6e6e6;
  color: rgb(0, 0, 0)!important;
  border: none;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  outline: none;
}
.imageUpload .ImagUploadBox > div > div + div textarea + button + button:hover, .imageUploadPop div[class*="paper"] button + button:hover, .imageUploadPop div[data-test="sentinelStart"] + div button + button:hover
{
  background: #cfcfcf;
}
.imageUpload .ImagUploadBox > div > div + div textarea
{
  width: 100%;
  height: 30px !important;
}

.imageUpload .ImagUploadBox>div>div+div .drop-container
{
text-align:center;
}
.imageUpload .ImagUploadBox>div>div+div .drop-container p
{
font-size: 12px;
margin-bottom: 5px;
}
.imageUpload .ImagUploadBox>div>div+div .drop-container input
{

    font-size: 12px;
    margin-bottom: 5px;

}

.imageUpload .ImagUploadBox > div > div img
{
 object-fit: contain;
 width: 100%;
 object-position: center center;
 height: 100%;
}
.imageUploadPop
{
  text-align: center;
}
.imageUploadPop div[class*="paper"], .imageUploadPop div[data-test="sentinelStart"] + div
{
  max-width: 600px;
  display: inline-block;
  margin-top: 50px;
  border: none;
  position: relative;
}
.imageUploadPop div[class*="paper"] input, .imageUploadPop div[data-test="sentinelStart"] + div input
{
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
.imageUploadPop div[class*="paper"] button, .imageUploadPop div[data-test="sentinelStart"] + div button
{
  float:right;
  margin-left: 10px;
}
.imageUploadPop div[class*="paper"] span.close, .imageUploadPop div[data-test="sentinelStart"] + div span.close
{
  position: absolute;
  right: 20px;
  top:20px;
  cursor: pointer;
  font-size: 13px;
}
.modalPopup
{
  display: block;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
.modalPopup .popupRow
{
  display: inline-block;
  width: 100%;
}
.modalPopup .popupRow label
{
  width: 100%;
  display: inline-block;
}
.modalPopup .popupRow input
{
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 20px;
}
.modalPopup .popupRow button
{
  float: right;
}
.modalPopup .popupRow button:first-child
{
  border-radius: 20px;
  background: #02b2e6;
  color: #fff!important;
  border: none;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  outline: none;
}
.modalPopup .popupRow button:last-child
{
  border-radius: 20px;
  background: #e6e6e6;
  color: rgb(0, 0, 0)!important;
  border: none;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
}
.goto
{
  border-radius: 20px;
  font-size: 13px;
  color:#02b2e6;
  border: 1px solid #02b2e6;
}
.goto:hover
{
  border-radius: 20px;
  font-size: 13px;
  color:#fff;
  background: #02b2e6;
  border: 1px solid #02b2e6;
}
#child_product .input-group button
{
  border-radius: 20px;
  background: #02b2e6;
  color: #fff!important;
  border: none;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  outline: none;
  margin-bottom: 10px;
}
#child_product  .ag-theme-alpine
{
  padding: 0px;
  margin-bottom: 10px;
}
#child_product .ag-theme-alpine .ag-ltr .ag-cell {
  border-right-width: 1px;
  font-size: 11px;
  font-weight: normal;
}
#child_product .ag-theme-alpine .ag-header-row{
  height: 35px !important;
}
.infoLabel
{
  font-size: 11px;
  font-weight: normal;
  width: calc(100% - 300px);
  display: inline-block;
  margin-left: 300px;
  top: -8px;
  position: relative;
  color: #aaa;
}
.ProdutsAddPage
{
  position: absolute;
  left:0px;
  width:100%;
  height: calc(100% - 39px);
  top: 38px;
}
.ProdutsAddPage .content-warpper
{
  position: absolute;
  left: 0px;
  top:96px;
  overflow-y: auto;
  height: calc(100% - 100px)
}
.ProdutsAddPage .BasicPdts .btnTabs
{
  /* position: relative; */
  height: 45px;
  width: calc(100% - 45px);
  background: #fff;
  display: inline-block;
  position: fixed;
  top: 133px;
  padding-top: 8px;
  z-index: 9;
}
.ProdutsAddPage .BasicPdts .btnTabs + div
{
  margin-top: 35px;
}
.ProdutsAddPage .BasicPdts .btnTabs button
{
  position: fixed;
  left: 20px;
}
.ProdutsAddPage .BasicPdts .btnTabs button:last-child
{
  left: 115px;
}
/* Style the tab */
.tab {
  float: left;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  /* width: 50%; */
  height: 200px;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: blue;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #ccc;
}

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

.shippstation{
  display: flex;
}.shipstationsRow{
  width: 33%;
  display: inline-block;
}
.shipstation-basicinfo{
  padding: 23px;
}
.shipstationsRow .toggleBtn{
  position: relative;
}

.shipstation-orders table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.shipstation-orders td, .shipstation-orders th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.shipstation-orders tr:nth-child(even) {
  background-color: #dddddd;
}
.ag-status-name-value span.component {
  font-weight: 400;
}